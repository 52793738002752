.attachments .ql-container {
  font-family: Manrope;
}

.attachments .ql-editor {
  padding: 0;
  font-weight: 400;
}

.attachments .ql-editor.ql-blank::before {
  left: 0px;
  /* color:  */
  font-weight: 300;
  font-style: normal;
  color: #9ca3af;
}

.attachments .ql-bubble .ql-tooltip {
  z-index: 1;
}
