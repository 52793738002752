@font-face {
  font-family: "Manrope";
  src: url("../fonts/Manrope/Manrope-ExtraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Manrope";
  src: url("../fonts/Manrope/Manrope-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Manrope";
  src: url("../fonts/Manrope/Manrope-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Manrope";
  src: url("../fonts/Manrope/Manrope-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Manrope";
  src: url("../fonts/Manrope/Manrope-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Manrope";
  src: url("../fonts/Manrope/Manrope-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Manrope";
  src: url("../fonts/Manrope/Manrope-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
  font-display: block;
}
