@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.public-layout {
  background: #e1ecfcb2;
  background-size: 500% 500%;
  animation: gradient 60s ease infinite;
  /*"faked" black background make sure to add last or it will appear before the transparent/colored layer*/
}

.main-layout {
  background: #e1ecfcb2
    /*"faked" black background make sure to add last or it will appear before the transparent/colored layer*/;
}

.private-layout {
  background: #e1ecfcb2;
  background-size: 500% 500%;
  animation: gradient 60s ease infinite;
  /*"faked" black background make sure to add last or it will appear before the transparent/colored layer*/
}
