@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.default-button {
  @apply transition-opacity duration-500 ease-in-out z-[1] overflow-hidden;
}

.default-button {
  background: linear-gradient(
    89.55deg,
    #4d3dd5 -13.7%,
    #7a6ff0 6.72%,
    #a39dfd 21.77%,
    #a4a5fb 42.04%,
    #59cbe4 74.84%,
    rgba(29, 127, 243, 0.886708) 93.78%,
    rgba(36, 77, 221, 0.79) 109.94%
  );
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  background-size: 400% 400%;
  animation: gradient 10s ease infinite;
}

.white-button-bg {
  background: rgb(252, 251, 251) !important;
}

.default-button-bg {
  border-radius: 6px;
  position: absolute;
  background: rgba(0, 0, 0, 1);
  opacity: 1;
  top: 0;
  left: 0;
  height: 105%;
  width: 105%;
  -webkit-transition: background 0.5s ease-in-out;
  -moz-transition: background 0.5s ease-in-out;
  -o-transition: background 0.5s ease-in-out;
  transition: background 0.5s ease-in-out;
  z-index: 2;
}

.default-button-bg:hover {
  background: rgba(0, 0, 0, 0);
}

.white-button-bg:hover {
  background: rgba(0, 0, 0, 0) !important;
}

.default-button-bg:active {
  background: rgba(0, 0, 0, 0);
}

.default-button-bg:focus {
  background: rgba(0, 0, 0, 0);
}

.button-text {
  color: white;
  z-index: 3;
}
