@keyframes animated-input {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.input-bg:focus-within {
  background: black;
  background-size: 200% 200%;
  animation: animated-input 3s ease infinite;
}
