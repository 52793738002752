@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./fonts.css";

html,
body,
#root {
  @apply h-full max-w-full w-full mx-auto;
  @apply font-manrope;
}

#root {
  @apply flex flex-col;
}

@keyframes animatedgradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes shine {
  to {
    background-position: 200% center;
  }
}

@keyframes svg-shine {
  0% {
    fill: gray;
  }
  25% {
    fill: #dc88dee0;
  }
  50% {
    fill: #8159d7;
  }
  75% {
    fill: #9bb6fb;
  }
  100% {
    fill: gray;
  }
}
.cool-link {
  position: relative;
  color: #000;
  text-decoration: none;
}

.cool-link::after {
  position: absolute;
  content: "";
  display: block;
  width: 100%;
  bottom: 0;
  height: 2px;
  background: -webkit-linear-gradient(
    288deg,
    #dc88dee0 25%,
    #8159d7ef 45%,
    #9bb6fb 75%
  );
  animation: animatedgradient 3s linear infinite;
  background-size: 200% 200%;
}

.modal-open {
  overflow-y: hidden;
}

.svg-gradient {
  fill: gray;
  animation-name: svg-shine;
  animation-duration: 0s;
  animation-iteration-count: infinite;
}

.svg-gradient:hover {
  animation-name: svg-shine;
  animation-duration: 6s;
  animation-iteration-count: infinite;
}

.text-gradient {
  background: black;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-gradient:hover {
  background: -webkit-linear-gradient(
    0deg,
    rgba(220, 136, 222, 1) 4%,
    rgba(129, 89, 215, 1) 27%,
    rgba(155, 182, 251, 1) 52%,
    rgba(129, 89, 215, 1) 76%,
    rgba(220, 136, 222, 1) 96%
  );

  background-size: 200% auto;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  animation: shine 6s linear infinite;
}

.text-gradient-forced {
  background: -webkit-linear-gradient(
    0deg,
    rgba(220, 136, 222, 1) 4%,
    rgba(129, 89, 215, 1) 27%,
    rgba(155, 182, 251, 1) 52%,
    rgba(129, 89, 215, 1) 76%,
    rgba(220, 136, 222, 1) 96%
  );

  background-size: 200% auto;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  animation: shine 6s linear infinite;
}

.dragging-group.active {
  cursor: grabbing;
  cursor: -webkit-grabbing;
}

.solution-gradient-outline:hover::before {
  content: "";
  position: absolute;
  top: -1px;
  bottom: -1px;
  left: -1px;
  right: -1px;
  background: -webkit-linear-gradient(
    0deg,
    rgba(220, 136, 222, 1) 4%,
    rgba(129, 89, 215, 1) 27%,
    rgba(155, 182, 251, 1) 52%,
    rgba(129, 89, 215, 1) 76%,
    rgba(220, 136, 222, 1) 96%
  );

  z-index: -1;
}

.solution-gradient-outline-forced::before {
  content: "";
  position: absolute;
  top: -2px;
  bottom: -2px;
  left: -2px;
  right: -2px;
  background: -webkit-linear-gradient(
    0deg,
    rgba(220, 136, 222, 1) 4%,
    rgba(129, 89, 215, 1) 27%,
    rgba(155, 182, 251, 1) 52%,
    rgba(129, 89, 215, 1) 76%,
    rgba(220, 136, 222, 1) 96%
  );

  z-index: -1;
}

.gradient-outline:hover::before {
  content: "";
  position: absolute;
  top: -3px;
  bottom: -3px;
  left: -3px;
  right: -3px;
  background: -webkit-linear-gradient(
    0deg,
    rgba(220, 136, 222, 1) 4%,
    rgba(129, 89, 215, 1) 27%,
    rgba(155, 182, 251, 1) 52%,
    rgba(129, 89, 215, 1) 76%,
    rgba(220, 136, 222, 1) 96%
  );
  border-radius: 10px;
  z-index: -1;
}

.gradient-outline-forced::before {
  content: "";
  position: absolute;
  top: -2px;
  bottom: -2px;
  left: -2px;
  right: -2px;
  background: -webkit-linear-gradient(
    0deg,
    rgba(220, 136, 222, 1) 4%,
    rgba(129, 89, 215, 1) 27%,
    rgba(155, 182, 251, 1) 52%,
    rgba(129, 89, 215, 1) 76%,
    rgba(220, 136, 222, 1) 96%
  );
  border-radius: 10px;
  z-index: -1;
}

.gradient-outline-forced-roundless::before {
  content: "";
  position: absolute;
  top: -1px;
  bottom: -1px;
  left: -1px;
  right: -1px;
  background: -webkit-linear-gradient(
    0deg,
    rgba(220, 136, 222, 1) 4%,
    rgba(129, 89, 215, 1) 27%,
    rgba(155, 182, 251, 1) 52%,
    rgba(129, 89, 215, 1) 76%,
    rgba(220, 136, 222, 1) 96%
  );

  z-index: -1;
}

.selected-option-gradient {
  background: linear-gradient(
    91.67deg,
    #f1e8ff -23.02%,
    rgba(177, 217, 245, 0.69) 151.52%
  );

  border-color: transparent;
  border-left: 3px solid #000000;
  padding-left: 21px;
}

.text-white-stroke {
  -webkit-text-stroke: 0.6px #ffffff;
}

.text-white-stroke-sm {
  -webkit-text-stroke: 0.15px #ffffff;
}

.hovered-selected-option-gradient:hover {
  background: linear-gradient(
    93.34deg,
    rgba(240, 228, 255, 0.44) -19.42%,
    rgba(213, 229, 251, 0.2288) 145.47%
  );

  border-color: transparent;
  border-left: 3px solid #d1d5db;
  padding-left: 21px;
}

.star-gradient {
  fill: #e5e9f2;
}
.star-gradient:hover {
  fill: linear-gradient(
    131.64deg,
    rgba(220, 136, 222, 0.88) -8.22%,
    rgba(129, 89, 215, 0.935536) 46.6%,
    #9bb6fb 110.24%
  );
}

[type="checkbox"]:hover {
  border: 1.5px solid;
  border-image-source: -webkit-linear-gradient(
    0deg,
    rgba(220, 136, 222, 1) 4%,
    rgba(129, 89, 215, 1) 27%,
    rgba(155, 182, 251, 1) 52%,
    rgba(129, 89, 215, 1) 76%,
    rgba(220, 136, 222, 1) 96%
  );
  border-image-slice: 1;
}

[type="checkbox"]:checked {
  background-image: url(./../images/checkmark.svg);
  background-size: 75% 75%;
  border: 1.5px solid;
  border-image-source: -webkit-linear-gradient(
    0deg,
    rgba(220, 136, 222, 1) 4%,
    rgba(129, 89, 215, 1) 27%,
    rgba(155, 182, 251, 1) 52%,
    rgba(129, 89, 215, 1) 76%,
    rgba(220, 136, 222, 1) 96%
  );
  border-image-slice: 1;
}

[type="password"]:focus {
  --tw-ring-shadow: none;
}

input[type="range"] {
  -webkit-appearance: none;
}

input[type="range"]::-webkit-slider-runnable-track {
  width: 300px;
  height: 1px;
  background: #606060;
  border: none;
  border-radius: 3px;
}
input[type="range"]::-webkit-slider-runnable-track:hover {
  background: linear-gradient(
    131.64deg,
    rgba(220, 136, 222, 0.88) -8.22%,
    rgba(129, 89, 215, 0.935536) 46.6%,
    #9bb6fb 110.24%
  );
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: none;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: #000;
  margin-top: -7px;
}

input[type="range"]::-webkit-slider-thumb:hover {
  background: linear-gradient(
    131.64deg,
    rgba(220, 136, 222, 0.88) -8.22%,
    rgba(129, 89, 215, 0.935536) 46.6%,
    #9bb6fb 110.24%
  );
}

input[type="range"]:focus {
  outline: none;
}

.hover-list-item-background {
  background: linear-gradient(
    131.64deg,
    rgba(220, 136, 222, 0.0968) -8.22%,
    rgba(129, 89, 215, 0.102909) 46.6%,
    rgba(155, 182, 251, 0.11) 110.24%
  );
}

.select-list-item-background {
  background: linear-gradient(
    91.67deg,
    #f1e8ff -23.02%,
    rgba(177, 217, 245, 0.69) 151.52%
  );
}

.bg-gradient {
  background: linear-gradient(
    131.64deg,
    rgba(220, 136, 222, 0.88) -8.22%,
    rgba(129, 89, 215, 0.935536) 46.6%,
    #9bb6fb 110.24%
  );
}

.fade {
  overflow-y: auto;
  -webkit-mask-image: linear-gradient(
    90deg,
    transparent 0,
    black var(--left-mask-size, 0),
    black calc(100% - var(--right-mask-size, 0)),
    transparent 100%
  );
  mask-image: linear-gradient(
    90deg,
    transparent 0,
    black var(--left-mask-size, 0),
    black calc(100% - var(--right-mask-size, 0)),
    transparent 100%
  );
}

/* .fade.is-left-overflowing {
  --left-mask-size: 28px;
  --right-mask-size: 1px;
} */

/* .fade.is-right-overflowing {
  --right-mask-size: 28px;
} */

.hide-scroll::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scroll {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

::-webkit-scrollbar {
  width: 10px;
}
.scrollbar::-webkit-scrollbar {
  width: 1px;
}
/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0px transparent;
  visibility: hidden;
  border-radius: 5px;
}
::-webkit-scrollbar-track-piece {
  background: transparent;
  visibility: hidden;
}
::-webkit-scrollbar-corner {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(128, 128, 128, 0.4);
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(128, 128, 128, 0.6);
}

.tooltiptoparrow::after {
  content: "";
  color: white;
  position: absolute;
  top: -7px;
  left: 50%;
  margin-left: -8px;
  border: 8px solid;
  border-color: transparent transparent white transparent;
}
.tooltipbottomarrow::after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: -8px;
  top: -9px;
  border: 8px solid;
  border-color: #fff transparent transparent transparent;
}

.tooltipbadgetoparrow::after {
  content: "";
  color: #fff;
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #fff transparent;
}

.tooltipbadgebottomarrow::after {
  content: "";
  color: #fff;
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #fff transparent transparent transparent;
}

@screen sm {
  .layout-width {
    @apply w-full max-w-[90%] mx-auto;
  }
}
.Toastify .Toastify__close-button {
  align-self: center;
}
.Toastify .Toastify__toast {
  border-radius: 0.375rem;
}

.multiselect [type="text"]:focus {
  --tw-ring-shadow: none;
}
input:focus {
  border: none;
  overflow: auto;
  outline: none;

  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
textarea:focus {
  border: none;
  overflow: auto;
  outline: none;

  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
input:focus {
  box-shadow: none !important;
}
input:focus,
:focus {
  overflow: visible;
}

.react-pdf__Page__canvas {
  margin: 0 auto;
  width: 80% !important;
  height: 100% !important;
}

select:focus {
  outline: 0px solid transparent;
  outline-offset: 1px;
  --tw-ring-inset: var(--tw-empty, /*!*/ /*!*/);
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #757575;

  box-shadow: none !important;
  border-color: #757575;
}

.custom-select-icon {
  background-image: url(./../images/select-icon.svg) !important;
  background-size: 8px 14px;
}

/*  FAST COMMENTS EXTRA STYLES */
.fast-comments-profile {
  background-color: white !important;
}
/**/
.react-tooltip.white-tooltip {
  background-color: white !important;
  opacity: 1 !important;
  color: black !important;
  border: 1px solid black;
}

.white-tooltip.react-tooltip__place-top > .react-tooltip-arrow {
  border-bottom: 1px solid black !important;
  border-right: 1px solid black !important;
}

.white-tooltip.react-tooltip__place-right > .react-tooltip-arrow {
  border-right: 1px solid black !important;
  border-bottom: 1px solid black !important;
}

.white-tooltip.react-tooltip__place-bottom > .react-tooltip-arrow {
  border-bottom: 1px solid black !important;
  border-right: 1px solid black !important;
}
/* haven't check this last*/
.white-tooltip.react-tooltip__place-left > .react-tooltip-arrow {
  border-top: 1px solid black !important;
  border-left: 1px solid black !important;
}
